// src/Providers.js

import React from 'react';
import { AuthProvider } from './contexts/AuthContext';
import { TokenProvider } from './contexts/TokenContext';
import { SocketProvider } from './contexts/SocketContext';
import { SessionProvider } from './contexts/SessionContext';
import { HelmetProvider } from 'react-helmet-async';

const Providers = ({ children }) => {
    return (
        <HelmetProvider>
            <AuthProvider>
                <SessionProvider>
                    <TokenProvider>
                        <SocketProvider>
                            {children}
                        </SocketProvider>
                    </TokenProvider>
                </SessionProvider>
            </AuthProvider>
        </HelmetProvider>
    );
};

export default Providers;

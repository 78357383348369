import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Providers from './Providers';
import './App.css';
import {} from './firebaseConfig';

// Lazy Load Screens
const Home = lazy(() => import('./screens/Home'));
const About = lazy(() => import('./screens/About'));
const Login = lazy(() => import('./screens/Login'));
const Welcome = lazy(() => import('./screens/Welcome'));
const Welcomec = lazy(() => import('./screens/Welcomec'));
const Results = lazy(() => import('./screens/Results'));
const Contact = lazy(() => import('./screens/Contact'));
const Stream = lazy(() => import('./screens/Stream'));
const Payment = lazy(() => import('./screens/Payment'));
const StreamRoom = lazy(() => import('./screens/StreamRoom'));
const Profile = lazy(() => import('./screens/Profile'));
const Registration = lazy(() => import('./screens/Registration'));
const PreStream = lazy(() => import('./screens/PreStream'));

// Error Boundary Component for handling errors
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught in ErrorBoundary: ", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong. Please try again later.</h1>;
        }

        return this.props.children; 
    }
}

function App() {
    return (
        <Providers>
            <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
                <div className="App">
                    <Header />
                    <main>
                        <ErrorBoundary>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/about" element={<About />} />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/welcome" element={<Welcome />} />
                                    <Route path="/welcomec" element={<Welcomec />} />
                                    <Route path="/results" element={<Results />} />
                                    <Route path="/registration" element={<Registration />} />
                                    <Route path="/contact" element={<Contact />} />
                                    <Route path="/stream" element={<Stream />} />
                                    <Route path="/prestream/:userId" element={<PreStream />} />
                                    <Route path="/payment" element={<Payment />} />
                                    <Route path="/profile" element={<Profile />} />
                                    <Route path="/streamroom/:userId" element={<StreamRoom />} />
                                </Routes>
                            </Suspense>
                        </ErrorBoundary>
                    </main>
                    <Footer />
                </div>
            </Router>
        </Providers>
    );
}

export default App;
